/* colors */
/* font-family */
/* easing */
#lower #main-view h2 {
  padding: 0 10px;
}

#lower #main-view h2 strong {
  line-height: 1.6;
}

#lower .sec01 .sec-head p {
  text-align: center;
  font-size: 14px;
}

#lower .sec01 .sec-head p span {
  display: inline-block;
}

@media screen and (min-width: 600px) {
  #lower #main-view h2 strong {
    line-height: 2;
  }
}

@media screen and (min-width: 800px) {
  #lower .sec01 .sec-head p {
    font-size: 16px;
  }
  #lower .sec01 .sec-head p span {
    display: block;
  }
}

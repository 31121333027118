@charset "utf-8";

@import "variables";


#lower{
	#main-view{
	 	h2{
	 		padding: 0 10px;
	 		strong {
				line-height: 1.6;
	 		}
	 	}
	}
	.sec01{
		.sec-head{
			p{
				text-align: center;
				font-size: 14px;
				span{
					display: inline-block;
				}
			}
		}
	}
}


@media screen and (min-width: 600px) {
	#lower{
		#main-view{
		 	h2{
		 		strong {
					line-height: $lineHeight;
		 		}
		 	}
		}
	}
}


@media screen and (min-width: 800px) {
	#lower{
		.sec01{
			.sec-head{
				p{
					font-size: 16px;
					span{
						display: block;
					}
				}
			}
		}
	}

}